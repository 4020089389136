<template>
    <div v-if="loaded">
        <div
            v-for="profile in profiles"
            :key="`rec-${profile.author.id}`"
            class="d-flex w-100 mt-2"
        >
            <nuxt-link
                :to="localePath(`/${profile.author.slug}`)"
                class="d-flex d-link pr-1 mr-h w-100"
                v-profile="{
                    modelId: profile.author.id,
                    modelName: profile.author.type,
                    placement: 'left'
                }"
            >
                <div
                    class="pic profile-shadow elevate"
                    :class="{user: profile.author.type == 'user'}"
                >
                    <WebpPicture
                        :src="profile.author.photo || profile.author.logo"
                        alt=""
                        loading="lazy"
                        width="40"
                        height="40"
                        imgClass="block-image"
                    />
                </div>
                <div class="ml-1 pl-h">
                    <div class="fw-700 f-small">{{ profile.author.displayName || profile.author.name }}</div>
                    <div class="color-gray-0 f-small">{{profile.author.followers}} {{ $tc('general.followersPlural', profile.author.followers) }}</div>
                </div>
            </nuxt-link>

            <Follow
                class="btn-secondary fw-600 btn-sm follow-recomend mr-auto"
                :modelName="profile.author.type"
                :modelId="profile.author.id"

                @follow="profile.author.followers++"
                @unfollow="profile.author.followers--"
            >
                <template v-slot:follow>
                    <div class="company-follow-button f-small p-h">
                        <span class="icon-ic-user-add fw-normal" />
                    </div>
                </template>

                <template v-slot:following>
                    <div class="company-follow-button f-small p-h bg-color-rivulet-label">
                        <span class="icon-ic-tick fw-normal f-10 color-content-bg" />
                    </div>
                </template>
            </Follow>
        </div>
    </div>

    <div v-else>
        <div
            v-for="i in length"
            :key="`rec-ph-${i}`"
            class="d-flex w-100 mt-2 skeleton"
        >
            <div class="d-flex d-link pr-1 mr-h w-100">
                <div class="pic elevate" />
                <div class="ml-1 pl-h">
                    <div class="mb-1 text"/>
                    <div class="text2"/>
                </div>

            </div>
            <div class="button" />
        </div>
    </div>
</template>

<script>
import Follow from '~/components/profile/Follow.vue';
import WebpPicture from '~/components/WebpPicture.vue';

export default {
    components: {
        Follow,
        WebpPicture
    },

    props: {
        model: {
            type: String
        },

        length: {
            type: Number,
            default: 5
        }
    },

    data () {
        return {
            profiles: [],
            loaded: false
        }
    },

    mounted () {
        this.getProfiles();
    },

    methods: {
        async getProfiles () {
            let data = await this.$axios.$get(
                `/publication/popularAuthors`,
                {
                    params: {excludefollowed: true},
                    progress: false
                }
            )

            if (data && data.popularAuthors) {
                this.profiles = data.popularAuthors;
                if (this.profiles.length) this.loaded = true;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    .company-follow-button {
        width:  32px;
        height: 32px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;

        @media screen and (min-width: 768px) {
            min-height: 0;

            .article-company.compact & {
                min-height: 32px;
            }
        }
    }

    .pic {
        width: 40px;
        height: 40px;
        border-radius: 8px;
        overflow: hidden;
        flex-shrink: 0;

        &.user {
            border-radius: 50%;
        }
    }

    .follow-recomend {
        width: 32px;
        height: 32px;
        flex-grow: 0;
        flex-shrink: 0;
        padding: 0;
    }

    .skeleton {
        pointer-events: none;
        animation: 2s linear 0s infinite flicker;


        &:nth-child(2n+1) {animation-delay: 0.2s;}
        &:nth-child(3n+1) {animation-delay: 0.4s;}
        &:nth-child(4n+1) {animation-delay: 0.6s;}
        &:nth-child(5n+1) {animation-delay: 0.8s;}

        .text,
        .text2,
        .button,
        .pic {
            background-color: var(--color-cases-gray-4);
        }

        .text {
            width: 95px;
            height: 16px;
            border-radius: 4px;
        }
        .text2 {
            width: 60px;
            height: 12px;
            border-radius: 4px;
        }

        .button {
            width:  32px;
            height: 32px;
            border-radius: 50%;
            flex-shrink: 0;
        }
    }

    @keyframes flicker {
        0% { opacity: 1; }
        50% { opacity: 0.5; }
        100% { opacity: 1; }
    }
</style>
