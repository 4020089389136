<i18n>
{
    "uk": {
        "title": "Гарячі вакансії",
        "all": "Всі вакансії",
        "to": "в",
        "post": "Додати вакансію"
    },
    "en": {
        "title": "Hot jobs",
        "all": "All jobs",
        "to": "to",
        "post": "Post a job"
    }
}
</i18n>

<template>
<div>
    <div class="l-heading  mb-2 d-flex justify-content-between align-items-center">
        <div class="fw-700 tt-upper d-flex">
            <img src="/images/flame.svg" width="16" height="16" class="mr-h" loading="lazy"> {{ $t('title') }}
        </div>
        <nuxt-link class="g-link fw-700 f-small" :to="localePath('/recruiter')">
            <span class="icon-ic-union icon" /> {{ $t('post') }}
        </nuxt-link>
    </div>
    <Card :lower="true">
        <div v-if="loaded">
            <nuxt-link 
                v-for="vacancy in vacancies"
                :key="vacancy.id"
                class="d-flex d-link vacancy"
                :to="localePath(`/vacancy/${vacancy.slug}?utm_source=cases&utm_medium=hot-vacancy`)"
            >
                <div 
                    class="pic elevate profile-shadow"
                    v-profile="{
                        modelId: vacancy.company.id,
                        modelName: 'company',
                        placement: 'left'
                    }"
                >
                    <WebpPicture
                        :src="vacancy.company.logo"
                        alt=""
                        loading="lazy"
                        width="24"
                        height="24"
                        imgClass="block-image"
                    />
                </div>
                <div class="ml-1">
                    <div class="mb-h title fw-700">{{ vacancy.title }}</div>
                    <div class="text">{{ $t('to') }} {{ vacancy.company.name }}</div>
                </div>
            </nuxt-link>
        </div>
        <div v-else>
            <div
                v-for="i in length"
                :key="`rec-ph-${i}`"
                class="d-flex w-100 skeleton vacancy"
            >
                <div class="pic" />
                <div class="ml-1 pl-h">
                    <div class="mb-h title"/>
                    <div class="text"/>
                </div>
            </div>
        </div>

        <nuxt-link
            v-if="!hideButton"
            class="btn-bg tt-upper fw-700 mt-3"
            :to="localePath('/vacancies')"
        >
            {{ $t('all') }}
        </nuxt-link>
    </Card>
</div>
</template>

<script>
import Card from '~/components/cards/Card.vue';
import WebpPicture from '~/components/WebpPicture.vue';

export default {
    components: {
        Card,
        WebpPicture
    },

    props: {
        length: {
            type: Number,
            default: 5
        },

        hideButton: {
            type: Boolean,
            default: false
        },

        query: {
            type: Object
        }
    },

    data () {
        return {
            vacancies: [],
            loaded: false
        }
    },

    mounted () {
        this.getVacancies();
    },

    methods: {
        async getVacancies () {
            let params = this.query || {};

            let data = await this.$axios.$get(
                `/vacancy/getHots`,
                {
                    params,
                    progress: false
                }
            )

            if (data && data.length) {
                this.vacancies = data;
                this.loaded = true;
            }
        }
    }
}
</script>

<style scoped lang="postcss">
    .vacancy + .vacancy {margin-top: 24px}

    .pic {
        width: 24px;
        height: 24px;
        overflow: hidden;
        border-radius: 4px;
        flex-shrink: 0;
    }

    .l-heading,
    .title,
    .text {
        font-size: 14px;
        line-height: 16px;
    }

    .skeleton {
        pointer-events: none;
        animation: 2s linear 0s infinite flicker;


        &:nth-child(2n+1) {animation-delay: 0.2s;}
        &:nth-child(3n+1) {animation-delay: 0.4s;}
        &:nth-child(4n+1) {animation-delay: 0.6s;}
        &:nth-child(5n+1) {animation-delay: 0.8s;}

        .title,
        .text,
        .pic {
            background-color: var(--color-cases-gray-4);
        }

        .title {
            width: 144px;
            height: 16px;
            border-radius: 4px;
        }
        .text {
            width: 104px;
            height: 16px;
            border-radius: 4px;
        }
    }
</style>